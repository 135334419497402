<template>
  <div>
    <BCard v-if="!loading">
      <h2>معلومات المستفيد</h2>
      <!-- {{ beneficiary }} -->
      <hr/>
      <BRow>
        <BCol cols="2">
          <div class="h5">اسم المستفيدين :</div>
        </BCol>
        <BCol>
          <div class="h5">{{ beneficiary.first_name + " " + beneficiary.last_name }}</div>
        </BCol>
      </BRow>
      <BRow class="mt-1" >
        <BCol cols="2">
          <div class="h5">رقم الموبايل :</div>
        </BCol>
        <BCol>
          <div class="h5">{{ beneficiary.phone_number }}</div>
        </BCol>
      </BRow>
      <BRow class="mt-1">
        <BCol cols="2">
          <div class="h5">تاريخ الميلاد :</div>
        </BCol>
        <BCol>
          <div class="h5">{{ beneficiary.dob }}</div>
        </BCol>
      </BRow>
      <BRow class="mt-1">
        <BCol cols="2">
          <div class="h5">الجنس :</div>
        </BCol>
        <BCol>
          <div class="h5">{{ beneficiary.gender==='M' ? 'ذكر':'أنثى'  }}</div>
        </BCol>
      </BRow>
      <BRow class="mt-1">
        <BCol cols="2">
          <div class="h5">الحالة :</div>
        </BCol>
        <!-- 
            // TODO beneficiary.status => translate word "completed"
         -->
        <BCol>
          <div class="h5 ">{{ beneficiary.status }}</div>
        </BCol>
      </BRow>
    </BCard>
    <!-- 
        // TODO when click to Collapse request API in booking 
     -->
    <BCard>
      <h3>حجوزات المستفيد</h3>
      <AppCollapse>
        <AppCollapseItem title="الفعاليات">
          <data-table
            ref="estatesTableee"
            :fields="fields"
            :ep="`/beneficiary-action-reservations/${id}?slug=E`"
          ></data-table>
        </AppCollapseItem>
        
        <AppCollapseItem title="النشاطات">
          <data-table
            ref="estatesTableee"
            :fields="fields"
            :ep="`/beneficiary-action-reservations/${id}?slug=A`"
          ></data-table>
        </AppCollapseItem>

        <AppCollapseItem title="القاعات">
          <data-table
            ref="estatesTableee"
            :fields="fields"
            :ep="`/beneficiary-hall-reservations/${id}`"
          ></data-table>
        </AppCollapseItem>

        <AppCollapseItem title="استوديو التسجيل">
          <data-table
            ref="estatesTableee"
            :fields="fields"
            :ep="`/beneficiary-studio-reservations/${id}`"
          ></data-table>
        </AppCollapseItem>

        <AppCollapseItem title="الإجراءات">
          <data-table
            ref="estatesTableee"
            :fields="fields"
            :ep="`/beneficiary-procedure-reservations/${id}`"
          ></data-table>
        </AppCollapseItem>

        <AppCollapseItem title="جلسات التوعية">
          <data-table
            ref="estatesTableee"
            :fields="fields"
            :ep="`/beneficiary-internalActivity-reservations/${id}?slug=awareness_sessions`"
          ></data-table>
        </AppCollapseItem>
        <AppCollapseItem title="متحف تفاعلي">
          <data-table
            ref="estatesTableee"
            :fields="fields"
            :ep="`/beneficiary-internalActivity-reservations/${id}?slug=interactive_museums`"
          ></data-table>
        </AppCollapseItem>
        <AppCollapseItem title="تدريبات تراثية">
          <data-table
            ref="estatesTableee"
            :fields="fields"
            :ep="`/beneficiary-internalActivity-reservations/${id}?slug=heritage_trainings`"
          ></data-table>
        </AppCollapseItem>
      </AppCollapse>
    </BCard>

    <BCard>
      <div class="" v-if="pendingBeneficiary.length">
        <hr />
        <b-row>
          <b-col cols="12">
            <BCard>
              <h3 class="mb-2">الخدمات الحالية للمستفيد</h3>
              <BRow
                v-for="(item, idx) in pendingBeneficiary"
                :key="idx"
                class="border mb-1 mx-1 p-1 rounded bg-light-success"
              >
                <BCol cols="2">
                  <div class="h5">الخدمة</div>
                  <div>{{ item.service_title }}</div>
                </BCol>
                <BCol cols="2">
                  <div class="h5">التاريخ</div>
                  <div>{{ item.created_at }}</div>
                </BCol>
                <BCol cols="2">
                  <div class="h5">رقم المكتب</div>
                  <div>{{ item.office_no }}</div>
                </BCol>
                <BCol cols="3">
                  <div class="h5">التاريخ</div>
                  <div>2000-10-10</div>
                </BCol>
                <BCol cols="2">
                  <div class="h5">الملاحظات</div>
                  <div>{{ item.service_note }}</div>
                </BCol>
              </BRow>
            </BCard>
          </b-col>
        </b-row>
      </div>
    </BCard>
  </div>
</template>

<script>
import AppCollapse from "@/@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@/@core/components/app-collapse/AppCollapseItem.vue";
import DataTable from "@/components/data-table/index.vue";
import { BCard, BCol, BRow } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { BCard, BRow, BCol, AppCollapse, AppCollapse, AppCollapseItem, DataTable },
  props: ["id"],
  data: () => {
    return {
      fields: [
        { label: "id", key: "id" },
        { label: "العنوان", key: "title" },
      ],
      fieldsProcedure: [
        { label: "id", key: "id" },
        { label: "العنوان", key: "procedure.title" },
        { label: "التاريخ", key: "created_at" },
      ],
    };
  },
  methods: {
    ...mapActions("settings/manageBeneficiary", ["getPending", "show"]),
  },
  computed: {
    ...mapGetters("settings/manageBeneficiary", [
      "pendingBeneficiary",
      "pendingLoading",
      "idBeneficiary",
      "beneficiary",
      "loading",
    ]),
  },
  created() {
    this.getPending(this.id);
    this.show(this.id);
  },
};
</script>
